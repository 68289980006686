<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-12-20 13:46:35
 * @ Description: Fixer's job page in the user's job dashboard section of the app.
 -->

<template>
    <div>
        <v-row
            justify="center"
            id="jobContainer"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 108px);' : 'max-height: calc(100vh - 72px);'"
            style="overflow-y: auto;"
        >
            <v-col
                cols="12"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : $vuetify.breakpoint.md ? 'max-width: 960px;' : $store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            >
                <v-row>
                    <!-- Heading -->
                    <v-col
                        cols="12"
                        :class="$vuetify.breakpoint.smAndDown && $store.state.user.userContext !== 'company' ? 'text-center' : ''"
                    >
                        <span
                            class="os-22-sb"
                        >
                            {{ selectedNavItem.heading }}
                        </span>
                    </v-col>
                    <!-- Heading -->

                    <!-- Desktop Navigation -->
                    <v-col
                        cols="12"
                        class="os-13-r"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <ul>
                            <li
                                v-for="(item, index) in topNavItems"
                                :key="index"
                                :style="item.selected ? '' : 'color: #8F9BB3;'"
                            >
                                <span
                                    :style="index > 0 ? $vuetify.breakpoint.xl ? 'padding-left: 4rem;' : 'padding-left: 2.5rem;' : ''"
                                    style="cursor: pointer; position: relative;"
                                    @click="changeTopNavSelection(index, true)"
                                >
                                    {{ item.text }}
                                </span>
                                <div
                                    style="background-color: #FFA858;  height: 2px; width: 35px; position: absolute;"
                                    :style="index > 0 ? $vuetify.breakpoint.xl ? 'margin-left: 4rem;' : 'margin-left: 2.5rem;' : ''"
                                    v-if="item.selected"
                                />
                            </li>
                        </ul>
                    </v-col>
                    <!-- Desktop Navigation -->

                    <!-- Mobile Navigation -->
                    <v-col
                        v-else-if="$vuetify.breakpoint.smAndDown"
                        cols="12"
                        class="pa-0 os-13-r"
                    >
                        <v-sheet
                            class="mx-auto"
                            max-width="100%"
                            style="background-color: #F9FBFD;"
                        >
                            <v-slide-group
                                multiple
                                show-arrows
                            >
                                <v-slide-item
                                    v-for="(item, index) in topNavItems"
                                    :key="index"
                                >
                                    <v-chip
                                        class="ma-2"
                                        outlined
                                        :color="item.selected ? '#FFA858' : '#8F9BB3'"
                                        :style="item.selected ? 'color: black;' : ''"
                                        style="font-size: 15px !important;"
                                        @click="changeTopNavSelection(index, true)"
                                    >
                                        {{ item.text }}
                                    </v-chip>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-col>
                    <!-- Mobile Navigation -->
                    
                    <v-col
                        cols="12"
                        style="position: relative;"
                    >
                        <!-- View Type Toggle Buttons -->
                        <v-btn
                            style="position: absolute; right: 66px; top: -41px; min-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="list"
                            :color="selectedView === 'list' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-l-lg"
                            @click="changeSelectedView('list')"
                            v-if="$vuetify.breakpoint.mdAndUp && !this.$route.path.includes('applicant/')"
                        >
                            <v-icon
                                :color="selectedView === 'list' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiFormatListBulleted }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            style="position: absolute; right: 16px; top: -41px; in-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="grid"
                            :color="selectedView === 'card' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-r-lg"
                            @click="changeSelectedView('card')"
                            v-if="$vuetify.breakpoint.mdAndUp && !this.$route.path.includes('applicant/')"
                        >
                            <v-icon
                                :color="selectedView === 'card' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiViewGridOutline }}
                            </v-icon>
                        </v-btn>
                        <!-- View Type Toggle Buttons -->

                        <!-- Router -->
                        <router-view
                            :selectedNavItem="selectedNavItem.text"
                            :selectedView="selectedView"
                        />
                        <!-- Router -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mdiFormatListBulleted, mdiViewGridOutline } from '@mdi/js'

    export default {
        name: 'WorkJobPage',

        watch: {
            '$route.path': {
                handler () {
                    this.getTopNavSelection()
                }
            },

            '$vuetify.breakpoint.smAndDown': {
                handler () {
                    this.changeSelectedView('card')
                }
            }
        },

        computed: {
            topNavItems () {
                if (this.$store.state.user.userContext === 'company') {
                    return [
                        {
                            text: 'All Jobs',
                            selected: true,
                            heading: 'All Jobs'
                        },
                        {
                            text: 'Active Jobs',
                            selected: false,
                            heading: 'Active Jobs'
                        },
                        {
                            text: 'Pending Jobs',
                            selected: false,
                            heading: 'Pending Jobs'
                        },
                        {
                            text: 'Drafts',
                            selected: false,
                            heading: 'Drafts'
                        },
                        {
                            text: 'History',
                            selected: false,
                            heading: 'History'
                        },
                        {
                            text: 'Applicants',
                            selected: false,
                            heading: 'Applicants'
                        }
                    ]
                } else return [
                    {
                        text: 'All Jobs',
                        selected: true,
                        heading: 'All Jobs'
                    },
                    {
                        text: 'Active Jobs',
                        selected: false,
                        heading: 'Active Jobs'
                    },
                    {
                        text: 'Pending Jobs',
                        selected: false,
                        heading: 'Pending Jobs'
                    },
                    {
                        text: 'History',
                        selected: false,
                        heading: 'History'
                    },
                    {
                        text: 'Favourites',
                        selected: false,
                        heading: 'Favourites'
                    }
                ]
            }
        },

        data () {
            return {
                selectedNavItem: {
                    text: 'All Jobs',
                    selected: true,
                    heading: 'All Jobs'
                },
                selectedView: 'card',
                mdiFormatListBulleted,
                mdiViewGridOutline
            }
        },

        beforeMount () {
            this.getTopNavSelection()

            if (!this.$route.query.view) {
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            } else this.selectedView = this.$route.query.view
        },

        methods: {
            getTopNavSelection () {
                let index = null

                if (this.$store.state.user.userContext === 'company') {
                    switch (this.$route.path) {
                    case '/job/all':
                        index = 0
                        break;
                    case '/job/active':
                        index = 1
                        break;
                    case '/job/pending':
                        index = 2
                        break;
                    case '/job/drafts':
                        index = 3
                        break;
                    case '/job/history':
                        index = 4
                        break;
                    case '/job/applicants':
                        index = 5
                        break;
                    }

                    if (index === null && this.$route.path.includes('/applicant/')) index = 5
                } else {
                    switch (this.$route.path) {
                    case '/job/search':
                        index = 0
                        break;
                    case '/job/active':
                        index = 1
                        break;
                    case '/job/applications':
                        index = 2
                        break;
                    case '/job/history':
                        index = 3
                        break;
                    case '/job/favourites':
                        index = 4
                        break;
                    }

                    if (index === null && this.$route.path.includes('/applicant/')) index = 2
                }

                this.changeTopNavSelection(index)
            },

            changeTopNavSelection (newSelectedItemIndex, changeRoute) {
                const currentlySelectedItemIndex = this.topNavItems.findIndex(itemToFind => itemToFind.selected)
                if (currentlySelectedItemIndex > -1) this.topNavItems[currentlySelectedItemIndex].selected = false

                this.topNavItems[newSelectedItemIndex].selected = true
                this.selectedNavItem = this.topNavItems[newSelectedItemIndex]

                if (changeRoute) {
                    if (this.$store.state.user.userContext === 'company') {
                        switch (this.selectedNavItem.text) {
                        case 'All Jobs':
                            if (this.$route.path !== '/job/all') this.$router.push({ path: '/job/all', query: { view: this.selectedView } })
                            break;
                        case 'Active Jobs':
                            if (this.$route.path !== '/job/active') this.$router.push({ path: '/job/active', query: { view: this.selectedView } })
                            break;
                        case 'Pending Jobs':
                            if (this.$route.path !== '/job/pending') this.$router.push({ path:'/job/pending', query: { view: this.selectedView } })
                            break;
                        case 'Drafts':
                            if (this.$route.path !== '/job/drafts') this.$router.push({ path: '/job/drafts', query: { view: this.selectedView } })
                            break;
                        case 'History':
                            if (this.$route.path !== '/job/history') this.$router.push({ path: '/job/history', query: { view: this.selectedView } })
                            break;
                        case 'Applicants':
                            if (this.$route.path !== '/job/applicants') this.$router.push({ path: '/job/applicants', query: { view: this.selectedView } })
                            break;
                        }
                    } else {
                        switch (this.selectedNavItem.text) {
                        case 'All Jobs':
                            if (this.$route.path !== '/job/search') this.$router.push({ path: '/job/search', query: { view: this.selectedView } })
                            break;
                        case 'Active Jobs':
                            if (this.$route.path !== '/job/active') this.$router.push({ path: '/job/active', query: { view: this.selectedView } })
                            break;
                        case 'Pending Jobs':
                            if (this.$route.path !== '/job/applications') this.$router.push({ path: '/job/applications', query: { view: this.selectedView } })
                            break;
                        case 'History':
                            if (this.$route.path !== '/job/history') this.$router.push({ path: '/job/history', query: { view: this.selectedView } })
                            break;
                        case 'Favourites':
                            if (this.$route.path !== '/job/favourites') this.$router.push({ path: '/job/favourites', query: { view: this.selectedView } })
                            break;
                        }
                    }
                }
            },

            changeSelectedView (viewType) {
                this.selectedView = viewType

                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            }
        }
    }
</script>
<style>
    /* @import url('@asset/style/style.fonts.css'); */

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    li span {
        display: block;
    }
</style>
